import { StyleSheet } from 'react-native'
import normalizer from "../../components/normalizer";

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
    },
    scrollContainer:{
        flex: 1,
        borderTopRightRadius: 20,
        borderTopLeftRadius: 20,
        backgroundColor: '#fff',
    },
    heroContainer: {
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap',
        paddingBottom: 100,
    },
    heroItem:{
        marginRight: '1%',
        marginLeft: '1%',
        marginTop: 15,
        width: '31.3%',
        justifyContent: 'flex-start',
    },
    heroRaceText:{
        textAlign: 'center',
        fontFamily: 'colby-bold-italic',
        textTransform: 'uppercase',
        fontSize: normalizer(15),

    },
    heroClassText:{
        textAlign: 'center',
        fontFamily: 'colby-italic',
    },
    heroImage: {
        resizeMode: 'contain',
        height: normalizer(100),
        width: normalizer(100),
        overflow: 'hidden',
        borderRadius: 20,
        justifyContent: 'center',
        alignSelf: 'center',
    },
    background:{
        flex: 1,
    },
    backgroundImage:{
        resizeMode: "repeat",
    },
    backButton: {
        fontFamily: 'morl-black',
        alignSelf: 'center',
        marginLeft: 15,
        fontSize: 20,
        color: '#fff',
        padding: normalizer(10),
    },
    hide: {
        display: 'none',
    },
    healthTracker: {
        flex: 1
    },
    headerBar: {
        fontFamily: 'morl-black',
        alignSelf: 'center',
        fontSize: normalizer(30),
        backgroundColor: 'rgba(0, 0, 0, 0)',
    },
    center:{
        textAlign: 'center',
    },
    creatureNumberText:{
        fontFamily: 'morl-black',
        alignSelf: 'center',
        fontSize: normalizer(30),
        padding: normalizer(10),
        color: '#009862',
    },
    downloadMain:{
        fontFamily: 'morl-black',
        flexDirection: 'row',
        alignSelf: 'stretch',
        fontSize: normalizer(30),
        backgroundColor: '#373C44',
        color: '#FFFFFF',
        height: normalizer(58),
    },
    downloadTextArea:{
        marginLeft: normalizer(10),
    },
    downloadButton:{
        fontFamily: 'morl-black',
        fontSize: normalizer(18),
        backgroundColor: '#2BB673',
        color: '#FFFFFF',
        borderRadius: 10,
        height: normalizer(30),
        lineHeight: normalizer(18),
        padding: normalizer(10),
        marginTop: normalizer(15),
        marginLeft: normalizer(10),
    },
    downloadTop:{
        fontFamily: 'morl-black',
        fontSize: normalizer(17),
        color: '#FFFFFF',
        textTransform: 'uppercase',
        paddingTop: normalizer(10),
        marginBottom: normalizer(-1),
    },
    downloadMiddle:{
        fontFamily: 'colby',
        fontSize: normalizer(13),
        color: '#FFFFFF',
    },
    downloadBottom:{
        fontFamily: 'colby-bold',
        fontSize: normalizer(13),
        color: '#FFFFFF',
    },
    downloadImage: {
        resizeMode: 'contain',
        height: normalizer(50),
        width: normalizer(50),
        overflow: 'hidden',
        borderRadius: 10,
        justifyContent: 'center',
        alignSelf: 'center',
        marginTop: normalizer(5),
        marginLeft: normalizer(5),
    },
})

const heroSheetInfo = StyleSheet.create({
    heroInfo:{
        flex: 1,
        flexDirection: 'row',
        padding : normalizer(10),
    },
    heroInfoName:{
        flex: 1,
    },
    heroImage: {
        resizeMode: 'contain',
        height: normalizer(120),
        width: normalizer(120),
        overflow: 'hidden',
        borderRadius: 20,
        marginRight: normalizer(8),
    },
})

const monsterSheetInfo = StyleSheet.create({
    monsterImage: {
        resizeMode: 'contain',
        height: normalizer(120),
        overflow: 'hidden',
        borderRadius: 15,
        alignSelf: 'flex-start',
        width: '100%',
    },
    monsterImageWrapper: {
        height: normalizer(120),
        marginTop: normalizer(15),
        marginRight: normalizer(15),
        marginLeft: normalizer(15),
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    },
})

const diceRoller = StyleSheet.create({
    diceRoller: {
        marginTop: normalizer(10),
        marginBottom: normalizer(10),
    },
    diceButtons: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        marginTop: 10,
    },
    twentyDice: {
        justifyContent: 'center',
        alignSelf: 'center',
    },
    diceImage: {
        resizeMode: 'contain',
        height: normalizer(100),
        width: normalizer(100),
        justifyContent: 'center',
        alignSelf: 'center',
    },
    rolledNumber: {
        fontSize: normalizer(40),
        textAlign: 'center',
    },
    rolledSection: {

        justifyContent: 'center',
        alignSelf: 'center',
    },
    rolledSectionText: {
        textAlign: 'center',
        fontFamily: 'colby-bold',
        textTransform: 'uppercase',
        fontSize: normalizer(25),
    },
    optionTextContainer: {
        textAlign: 'center',
        fontFamily: 'colby-bold',
        textTransform: 'uppercase',
        fontSize: normalizer(20),
    },
    critText: {
        color: '#000000',
    },
    critTextNone: {
        display: 'none',
    },
    handyDiceImg:{
        flex: 1,
        justifyContent: 'center',
        alignSelf: 'center',
    },
    diceText:{
        textAlign: 'center',
        fontFamily: 'colby-bold',
        textTransform: 'uppercase',
        fontSize: normalizer(20),
    },
})

const healthTracker = StyleSheet.create({
    container: {
        flex: 1,
        marginTop: normalizer(10),
        marginBottom: normalizer(10),
    },
    healthButtons: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        marginTop: 10,

    },
    healthText: {
        fontFamily: 'colby-bold',
        textAlign: 'center',
        alignSelf: 'center',
        fontSize: normalizer(60),
    },
    healthOtherText: {
        fontFamily: 'colby-bold',
        textAlign: 'center',
        alignSelf: 'center',
        fontSize: normalizer(15),
    }
})

export { styles, heroSheetInfo, diceRoller, healthTracker, monsterSheetInfo }

import * as React from 'react';
import {Image, StyleSheet, Text, View, ImageBackground} from 'react-native';
import { RectButton, ScrollView } from 'react-native-gesture-handler';
import {styles} from '../assets/styles/Styles';

import chimera from "../assets/images/monsters/CreatureSelect_Chimera.jpg";
import redeyedmice from "../assets/images/monsters/CreatureSelect_Red_Eyed_Mice.jpg";
import giantwolfspider from "../assets/images/monsters/CreatureSelect_Giant_Wolf_Spider.jpg";
import drowsydragon from "../assets/images/monsters/CreatureSelect_Drowsy_Dragon.jpg";
import gassydragon from "../assets/images/monsters/CreatureSelect_Gassy_Dragon.jpg";
import manywhelps from "../assets/images/monsters/CreatureSelect_Many_Whelps.jpg";
import spookygnat from "../assets/images/monsters/CreatureSelect_Spooky_Gnat.jpg";
import creepyportrait from "../assets/images/monsters/CreatureSelect_Creepy_Portrait.jpg";
import zombiebrothers from "../assets/images/monsters/CreatureSelect_Zombie_Brothers.jpg";
import bridgetroll from "../assets/images/monsters/CreatureSelect_Bridge_Troll.jpg";

import header_chimera from "../assets/images/monsters/Monster_Header_Chimera.jpg";
import header_redeyedmice from "../assets/images/monsters/Monster_Header_Red_Eyed_Mice.jpg";
import header_giatnwolfspider from "../assets/images/monsters/Monster_Header_Giant_Wolf_Spider.jpg";
import header_drowsydragon from "../assets/images/monsters/Monster_Header_Drowsy_Dragon.jpg";
import header_gassydragon from "../assets/images/monsters/Monster_Header_Gassy_Dragon.jpg";
import header_manywhelps from "../assets/images/monsters/Monster_Header_Many_Whelps.jpg";
import header_spookygnat from "../assets/images/monsters/Monster_Header_Spooky_Gnat.jpg";
import header_creepyportrait from "../assets/images/monsters/Monster_Header_Creepy_Portrait.jpg";
import header_zombiebrothers from "../assets/images/monsters/Monster_Header_Zombie_Brothers.jpg";
import header_bridgetroll from "../assets/images/monsters/Monster_Header_Bridge_Troll.jpg";

import background from "../assets/images/Dungeon_Tile_001.jpg";

export default function MonsterScreen({navigation}) {

  function Monster(monsterImg, headerMonsterImg, monsterName, monsterType, monsterHealth, numberOfMonsters) {
    this.monsterImg = monsterImg;
    this.headerMonsterImg = headerMonsterImg;
    this.monsterName = monsterName;
    this.monsterType = monsterType;
    this.monsterHealth = monsterHealth;
    this.numberOfMonsters = numberOfMonsters;
  }

  const BridgeTroll = new Monster(bridgetroll, header_bridgetroll, 'Bridge Troll', 'Giant', 85, 1);
  const Chimera = new Monster(chimera, header_chimera, 'Chimera', 'Beast', 45, 1);
  const CreepyPortrait = new Monster(creepyportrait, header_creepyportrait, 'Creepy Portrait', 'Undead', 30, 1);
  const DrowsyDragon = new Monster(drowsydragon, header_drowsydragon, 'Drowsy Dragon', 'Dragon', 50, 1);
  const GassyDragon = new Monster(gassydragon, header_gassydragon, 'Gassy Dragon', 'Dragon', 40, 1);
  const GiantWolfSpider = new Monster(giantwolfspider, header_giatnwolfspider, 'Giant Wolf Spider', 'Beast', 35, 1);
  const ManyWhelps = new Monster(manywhelps, header_manywhelps, 'Many Whelps', 'Dragon', 20, 5);
  const RedEyedMice = new Monster(redeyedmice, header_redeyedmice, 'Red-Eyed Mice', 'Beast', 5, 6);
  const SpookyGnat = new Monster(spookygnat, header_spookygnat, 'Spooky Gnat', 'Undead', 15, 1);
  const ZombieBrothers = new Monster(zombiebrothers, header_zombiebrothers, 'Zombie Brothers', 'Undead', 15, 2);

  const MonsterList = [BridgeTroll, Chimera, CreepyPortrait, DrowsyDragon, GassyDragon, GiantWolfSpider, ManyWhelps, RedEyedMice, SpookyGnat, ZombieBrothers];

  let createMonsterBoxes = () => {
    let monsterBoxes = [];
    MonsterList.forEach((monster, index) =>
        monsterBoxes.push(
            <MonsterButton
                key= {index}
                image={monster.monsterImg}
                name={monster.monsterName}
                onPress={ () => navigation.push('Monster', {monsterHeaderImg: monster.headerMonsterImg, monsterHealth: monster.monsterHealth, monsterNumber: monster.numberOfMonsters, fullMonsterName: monster.monsterName}) }
            />
        )
    );
    return monsterBoxes;
  };

  return (
  <View style={styles.container}>
    <ImageBackground source={background} style={styles.background} imageStyle={styles.backgroundImage}>
      <ScrollView style={styles.scrollContainer} >
        <View style={styles.heroContainer}>
          {createMonsterBoxes()}
          {/*<OptionButton icon="md-school"  label="Dynamic Gorilla"  onPress={() => WebBrowser.openBrowserAsync('https://dynamicgorilla.com')} />*/}
        </View>
      </ScrollView>
    </ImageBackground>
  </View>
  );
}

function MonsterButton({ image, name, onPress }) {
  return (
      <RectButton style={styles.heroItem} onPress={onPress}>
        <Image source={image} style={styles.heroImage} />
        <Text style={styles.heroRaceText}>{name}</Text>
      </RectButton>
  );
}


const pageStyle = StyleSheet.create({

});

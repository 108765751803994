import * as React from 'react';
import {Image, StyleSheet, Text, View, ImageBackground} from 'react-native';
import {ScrollView} from 'react-native-gesture-handler';
import {BorderlessButton} from 'react-native-gesture-handler';
import DiceRoll from "../components/DiceRoll";
import HealthTracker from "../components/HealthTracker";
import AbilityPointTracker from "../components/AbilityPointTracker";
import HeroNameGenerator from "../components/HeroNameGenerator";
import {useState} from "react";
import background from "../assets/images/Dungeon_Tile_001.jpg";
import {styles, heroSheetInfo} from '../assets/styles/Styles';
import {useEffect} from "react";

export default function HeroSheetScreen({route, navigation}) {

  const {charImage, charClass, charRace, charHealth, ultimateUses } = route.params;
  const [savedHealth, setSavedHealth] = useState(charHealth);
  const [maxHealth, setMaxHealth] = useState(charHealth);

  useEffect(() => {
    navigation.setOptions({
      headerTitle: "Hero Sheet",
      headerTitleAlign: 'center',
      headerTitleStyle: styles.headerBar,
      headerStyle: {borderBottomWidth: 0,},
      headerLeft: () => (<BorderlessButton onPress={() => navigation.goBack()}><Text
          style={styles.backButton}>Back</Text></BorderlessButton>),
    });
  });

  let retrieveChar = () => {
    AsyncStorage.getItem('currentRace').then(
        value =>{
          console.log(value)
        }
    );
  };

  return (

    <View style={styles.container}>
      <ImageBackground source={background} style={styles.background} imageStyle={styles.backgroundImage}>
        <ScrollView style={styles.scrollContainer}>
          <View style={heroSheetInfo.heroInfo}>
            <Image source={charImage} style={heroSheetInfo.heroImage} />
            <HeroNameGenerator heroClass = {charClass} heroRace = {charRace} style={heroSheetInfo.heroInfoName} />
          </View>
          <HealthTracker style={styles.healthTracker} health = {JSON.stringify(maxHealth)} />
          <AbilityPointTracker ultNumber = {JSON.stringify(ultimateUses)} />
          <DiceRoll/>
        </ScrollView>
      </ImageBackground>
    </View>
  );
}

const heroSheetStyles = StyleSheet.create({

});

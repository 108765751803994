import * as React from 'react';
import {Image, Platform, StatusBar, StyleSheet, View, Text} from 'react-native';
import * as SplashScreen  from 'expo-splash-screen';
import * as Font from 'expo-font';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import BottomTabNavigator from './navigation/BottomTabNavigator';
import HeroSheetScreen from "./screens/HeroSheetScreen";
import MonsterSheetScreen from "./screens/MonsterSheetScreen";
import MonsterCount from "./components/MonsterCount";
import useLinking from './navigation/useLinking';
import * as WebBrowser from "expo-web-browser";

import background from "./assets/images/Dungeon_Tile_001.jpg";
import {styles, heroSheetInfo} from './assets/styles/Styles';
import icon from "./assets/images/App_Icon_256x256.png";
import * as Linking from "expo-linking";


const Stack = createStackNavigator();

export default function App(props) {
  const [isLoadingComplete, setLoadingComplete] = React.useState(false);
  const [initialNavigationState, setInitialNavigationState] = React.useState();
  const containerRef = React.useRef();
  const { getInitialState } = useLinking(containerRef);

  Text.defaultProps = {
    ...Text.defaultProps,
    maxFontSizeMultiplier: 1,
  };

  const MyTheme = {
    dark: true,
    colors: {
      background: 'transparent',
    },
  };

  const forFade = ({ current, closing }) => ({
    cardStyle: {
      opacity: current.progress,
    },
  });


  const getBaseOsSync = () => {
    const userAgent = window.navigator.userAgent,
        platform = window.navigator.platform,
        macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iosPlatforms = ['iPhone'];

    let os = platform;

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'Windows';
    } else if (/Android/.test(userAgent)) {
      os = 'Android';
    } else if (!os && /Linux/.test(platform)) {
      os = 'Linux';
    }

    return os;
  };



  const displayAppDownload = () => {
    // DeviceInfo.getBaseOs().then(baseOs => {
    //   return <Text>The BAse OS is {baseOs}</Text>;
    // });

    if (Platform.OS === "web"){
      if(getBaseOsSync() === 'Android'){
        return <View style={styles.downloadMain}>
          <Text><Image source={icon} style={styles.downloadImage} /></Text>
          <View style={styles.downloadTextArea}>
            <Text style={styles.downloadTop}>Dungeon Sheet: Tap, Track, and Roll!</Text>
            <Text style={styles.downloadMiddle}>No internet required to use the app.</Text>
            <Text style={styles.downloadBottom}>Download FREE today!</Text>
          </View>
          <Text style={styles.downloadButton} onPress={() => Linking.openURL('https://play.google.com/store/apps/details?id=com.dynamicgorilla.dungeondoors')}>DOWNLOAD</Text>
        </View>;
      }
      if(getBaseOsSync() === 'iOS'){
        return <View style={styles.downloadMain}>
          <Text><Image source={icon} style={styles.downloadImage} /></Text>
          <View style={styles.downloadTextArea}>
            <Text style={styles.downloadTop}>Dungeon Sheet: Tap, Track, and Roll!</Text>
            <Text style={styles.downloadMiddle}>No internet required to use the app.</Text>
            <Text style={styles.downloadBottom}>Download FREE today!</Text>
          </View>
          <Text style={styles.downloadButton} onPress={() => Linking.openURL('https://apps.apple.com/us/app/dungeon-doors/id1505872964')}>DOWNLOAD</Text>
        </View>;
      }
      return ;
    }
    return ;
  };

  // Load any resources or data that we need prior to rendering the app
  React.useEffect(() => {
    async function loadResourcesAndDataAsync() {
      try {
        await SplashScreen.preventAutoHideAsync();
        // Load our initial navigation state
        setInitialNavigationState(await getInitialState());

        // Load fonts
        await Font.loadAsync({
          'colby-bold': require('./assets/fonts/Colby-Bold.otf'),
          'colby-bold-italic': require('./assets/fonts/Colby-Bold-Italic.otf'),
          'colby': require('./assets/fonts/Colby-Regular.otf'),
          'colby-italic': require('./assets/fonts/Colby-Regular-Italic.otf'),
          'morl-extra-black': require('./assets/fonts/Morl-ExtraBlack.otf'),
          "morl-black": require('./assets/fonts/Morl-Black.otf'),
          "MorlBlack": require('./assets/fonts/Morl-Black.otf'),
          'morl-bold': require('./assets/fonts/Morl-Bold.otf'),
          'morl': require('./assets/fonts/Morl-Regular.otf'),
        });
      } catch (e) {
        // We might want to provide this error information to an error reporting service
        console.warn(e);
      } finally {
        setLoadingComplete(true);
        setTimeout(() => { SplashScreen.hideAsync(); }, 2000);
        StatusBar.setBarStyle('light-content');
      }
    }

    loadResourcesAndDataAsync();
  }, []);

  if (!isLoadingComplete && !props.skipLoadingScreen) {
    return null;
  } else {
    return (
        <View style={appStyles.container}>
            {Platform.OS === 'ios' && <StatusBar barStyle="default" />}
            {displayAppDownload()}
            <NavigationContainer ref={containerRef} initialState={initialNavigationState}>
              <Stack.Navigator
                  headerMode="screen"
                  screenOptions={{
                    headerTintColor: '#fff',
                    headerStyle: { borderBottomWidth: 0, borderTopWidth: 0,},
                  }}
              >
                <Stack.Screen
                    name="Root"
                    component={BottomTabNavigator}
                    options={{
                      headerBackground: () => (
                          <View style={appStyles.headerStyle}><Image source={background} style={appStyles.background} /></View>
                      ),
                    }}
                />
                <Stack.Screen
                    name="Character"
                    component={HeroSheetScreen}
                    options={{
                      headerBackground: () => (
                          <View style={appStyles.headerStyle}><Image source={background} style={appStyles.background} /></View>
                      ),
                    }}
                />
                <Stack.Screen
                    name="Monster"
                    component={MonsterSheetScreen}
                    options={{
                      headerBackground: () => (
                          <View style={appStyles.headerStyle}><Image source={background} style={appStyles.background} /></View>
                      ),
                    }}
                />
                <Stack.Screen
                    name="MonsterCount"
                    component={MonsterCount}
                    options={{
                      headerBackground: () => (
                          <View style={appStyles.headerStyle}><Image source={background} style={appStyles.background} /></View>
                      ),
                    }}
                />
              </Stack.Navigator>
            </NavigationContainer>
        </View>
    );
  }
}

function openKickstarter() {
  WebBrowser.openBrowserAsync('https://dynamicgorilla.com/kickstarter');
}

const appStyles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#555555',
    maxWidth: 768,
  },
  bmBurgerButtonLeft: {
    position: 'absolute',
    width: 36,
    height: 30,
    left: 15,
    top: 20,
  },
  headerStyle:{
    flex: 1,
  },
  background:{
    width: '100%',
    height: '100%',
    resizeMode: 'cover',
  },

});

import React, {useEffect, useState} from 'react';
import {StyleSheet, Text, View, Button, TextInput, Image, ImageBackground} from 'react-native';
import {styles, healthTracker, monsterSheetInfo} from '../assets/styles/Styles';
import DiceRoll from "./DiceRoll";
import {useFocusEffect} from "@react-navigation/core";
import { BackHandler } from 'react-native';
import {BorderlessButton, ScrollView} from "react-native-gesture-handler";
import monster_up from "../assets/images/icons/monsters_up.jpg";
import monster_down from "../assets/images/icons/monsters_down.jpg";
import background from "../assets/images/Dungeon_Tile_001.jpg";


export default function MonsterCount({route, navigation}){
    const [monstersNumber, setMonstersNumber] = useState('0');
    const [currentDiceValue, setCurrentDiceValue] = useState(1);
    const [disableButton, setDisableButton] = useState(true);
    const { dice, monsterName, monsterHeader } = route.params;

    useEffect(() => {
        navigation.setOptions({
            headerTitle: "Number of Creatures",
            headerTitleAlign: 'center',
            headerTitleStyle: styles.headerBar,
            headerStyle: { borderBottomWidth: 0, },
            headerLeft: () => (<Text></Text>),
        });
    });

    function isSelectionModeEnabled(){
        return true;
    }

    useFocusEffect(
        React.useCallback(() => {
            const onBackPress = () => {
                if (isSelectionModeEnabled()) {
                    return true;
                } else {
                    return false;
                }
            };

            BackHandler.addEventListener('hardwareBackPress', onBackPress);

            return () =>
                BackHandler.removeEventListener('hardwareBackPress', onBackPress);
        }, [isSelectionModeEnabled])
    );


    function setDiceValue(value){
        setCurrentDiceValue(value);
    }

    function monsterUp(){
        if(monstersNumber < 8){
            setMonstersNumber(Number.parseInt(monstersNumber)+ 1);
            setDisableButton(false);
        }
    }

    function monsterDown(){
        if(monstersNumber > 1){
            setMonstersNumber(Number.parseInt(monstersNumber) - 1);
        }else if(monstersNumber > 0){
            setMonstersNumber(Number.parseInt(monstersNumber) - 1);
            setDisableButton(true);
        }
    }

    function disableDoneButton(value){
        setDisableButton(value);
    }
    function enableDone(newMonsterNumber) {
        setDisableButton(false);
    }

    return (
        <View style={styles.container}>
            <ImageBackground source={background} style={styles.background} imageStyle={styles.backgroundImage}>
            <ScrollView style={styles.scrollContainer} >
                <View style={monsterSheetInfo.monsterImageWrapper}><Image source={monsterHeader} style={monsterSheetInfo.monsterImage} /></View>
                <View>
                    <Text style={styles.creatureNumberText}>How Many {monsterName}?</Text>
                </View>
                {dice ?  <Text></Text>: <View style={healthTracker.healthButtons}>
                     <BorderlessButton onPress={() => monsterUp()}>
                        <Image source={monster_up} style={styles.heroImage} />
                    </BorderlessButton>
                     <Text style={healthTracker.healthText}>{monstersNumber}</Text>
                  <BorderlessButton onPress={() => monsterDown()} >
                        <Image source={monster_down} style={styles.heroImage} />
                    </BorderlessButton>
                </View> }

                {dice ? <DiceRoll onlySix={dice} setDiceValue={setDiceValue} setDisable={disableDoneButton} /> : <Text></Text>}

                <Button
                    title="Done"
                    disabled={disableButton}
                    onPress={() => {
                        navigation.navigate('Monster', { monsterBarSet: (dice ? currentDiceValue : monstersNumber)});
                    }}
                />
            </ScrollView>
            </ImageBackground>
        </View>
    );
}

const monsterCountStyle = StyleSheet.create({

});
import React, {useEffect, useState} from 'react';
import {StyleSheet, Text, View, Image} from 'react-native';
import {BorderlessButton} from 'react-native-gesture-handler';
import health_up from "../assets/images/icons/health_up.jpg";
import health_down from "../assets/images/icons/health_down.jpg";
import {styles, healthTracker} from '../assets/styles/Styles';
import header_giatnwolfspider from "../assets/images/monsters/Monster_Header_Giant_Wolf_Spider.jpg";

export default function HealthTracker(props){

    const [enabled, setEnabled] = useState(true);
    const [healthLoaded, setHealthLoaded] = useState(true);
    const [currentHealth, setCurrentHealth] = useState(parseInt(props.health) ? parseInt(props.health) : 0);
    const [maxHealth, setMaxHealth] = useState(parseInt(props.health) ? parseInt(props.health) : 100);
    const [currentMonster, setCurrentMonster] = useState(props.creature ? props.creature  : 'Unknown');

    useEffect(() => {
        if(healthLoaded){
            //retrieveHealth();
            //    Right now use default health. Update later
            setHealthLoaded(false);
        }
    });

    function healthUp(){
        setEnabled(false);
        if(currentHealth < maxHealth){
            setCurrentHealth(currentHealth + 1);
            // storeHealth(currentHealth + 1);
            //    Need to add storing the health
        }
        setEnabled(true);
    }
    function healthDown(){
        setEnabled(false);
        if(currentHealth > 0){
            if(currentHealth === 1 && currentMonster === 'Giant Wolf Spider'){
                props.navi.pop();
                props.navi.push('Monster', {monsterHeaderImg: header_giatnwolfspider, monsterHealth: 5, monsterNumber: 6, fullMonsterName: 'Spiderlings'});
            }else{
                setCurrentHealth(currentHealth - 1);
            }
        }
        setEnabled(true);
    }


    let storeHealth = (value) => {
        AsyncStorage.setItem('currentHealth', value.toString());
    };

    let retrieveHealth = () => {
        AsyncStorage.getItem('currentHealth').then(
            value =>{
                if(parseInt(value) !== 0){
                    setCurrentHealth(parseInt(value))
                }
            }
        );
    };

    return (
        <View style={healthTracker.container}>
            <View style={healthTracker.healthButtons}>
                <BorderlessButton onPress={() => healthUp()} enabled={enabled}>
                    <Image source={health_up} style={styles.heroImage} />
                </BorderlessButton>
                <View>
                    <Text style={healthTracker.healthText}>{currentHealth}</Text>
                    <Text style={healthTracker.healthOtherText}>HP Remaining</Text>
                </View>
                <BorderlessButton onPress={() => healthDown()} enabled={enabled}>
                    <Image source={health_down} style={styles.heroImage} />
                </BorderlessButton>
            </View>
        </View>
    );
}

const healthTrackerStyles = StyleSheet.create({

});

import * as React from 'react';
import {Image, ImageBackground, StyleSheet, Text, View} from 'react-native';
import {ScrollView} from 'react-native-gesture-handler';
import {BorderlessButton} from 'react-native-gesture-handler';
import DiceRoll from "../components/DiceRoll";
import HealthTracker from "../components/HealthTracker";
import {FontAwesome5} from "@expo/vector-icons";
import {useState} from "react";
import background from "../assets/images/Dungeon_Tile_001.jpg";
import {monsterSheetInfo, styles} from '../assets/styles/Styles';
import {useEffect} from "react";



export default function MonsterSheetScreen({route, navigation}) {

  const {monsterHeaderImg, monsterHealth, monsterNumber, monsterBarSet, fullMonsterName } = route.params;
  const [currentMonsterBars, setCurrentMonsterBars] = useState(1);
  const [showAdd, setShowAdd] = useState(false);

  useEffect(() => {

    navigation.setOptions({
      headerTitle: "Monster Sheet",
      headerTitleAlign: 'center',
      headerTitleStyle: styles.headerBar,
      headerStyle: { borderBottomWidth: 0, },
      headerLeft: () => (<BorderlessButton onPress={() => navigation.goBack()}><Text style={styles.backButton}>Back</Text></BorderlessButton>),
    });

    if(monsterNumber !== 1){
      if(fullMonsterName === 'Zombie Brothers'){
        setCurrentMonsterBars(monsterNumber)
      }
      else{
        if (monsterBarSet == null) {
          switch (fullMonsterName) {
            case 'Red-Eyed Mice':
              navigation.push('MonsterCount', {dice: true, monsterName: fullMonsterName, monsterHeader: monsterHeaderImg});
              break;
            case 'Many Whelps':
              navigation.push('MonsterCount', {dice: false, monsterName: fullMonsterName, monsterHeader: monsterHeaderImg});
              break;
            case 'Spiderlings':
              navigation.push('MonsterCount', {dice: true, monsterName: 'Spiderlings', monsterHeader: monsterHeaderImg});
              break;
            default:
              console.log('null');
              setCurrentMonsterBars(currentMonsterBars);
              break;
          }
        }
        else{
          setCurrentMonsterBars(parseInt(monsterBarSet));
        }
      }
    }
  });

  let createHealthBars = () => {
    let healthBars = [];
      for (let i = 0; i < currentMonsterBars; i++)
      {
        healthBars.push(
            <HealthTracker key={i} health = {JSON.stringify(monsterHealth)} creature = {fullMonsterName} navi = {navigation} />
        )
      }
    return healthBars;
  };

  return (
    <View style={styles.container}>
      <ImageBackground source={background} style={styles.background} imageStyle={styles.backgroundImage}>
      <ScrollView style={styles.scrollContainer}>
        <View style={monsterSheetInfo.monsterImageWrapper}><Image source={monsterHeaderImg} style={monsterSheetInfo.monsterImage} /></View>
        {createHealthBars()}
        <DiceRoll onlySix={false} />
      </ScrollView>
      </ImageBackground>
    </View>
  );
}

const monsterSheetStyles = StyleSheet.create({

});

import React, {useEffect, useState} from 'react';
import {StyleSheet, Text, View, Animated, Image} from 'react-native';
import {BorderlessButton} from 'react-native-gesture-handler';
import sixSided from "../assets/images/icons/six_sided_dice.png";
import sixSidedMonster from "../assets/images/icons/monster_dice.png";
import twentySided from "../assets/images/icons/twenty_sided_dice.png";
import handyDiceRoller from "../assets/images/icons/handy_dice_roller.jpg";
import {styles, diceRoller, healthTracker} from '../assets/styles/Styles';

export default function DiceRoll({onlySix, setDiceValue, setDisable}){

    const [enabled, setEnabled] = useState(true);
    const [diceNumber, setDiceNumber] = useState(0);
    const [rotationValue20, setRotationValue20] = useState(new Animated.Value(0)); // Initial value for opacity: 0
    const [rotationValue6, setRotationValue6] = useState(new Animated.Value(0));
    const [hideDice, setHideDice] = useState(false);

    useEffect(() => {
        if(onlySix !== 'undefined'){
            setHideDice(onlySix);
        }
        if(setDiceValue !== undefined){
            setDiceValue(diceNumber);
        }
    });

    const RotationalView = props => {

        return (
            <Animated.View // Special animatable View
                style={{
                    ...props.style,
                    transform:  [{ rotate: RotateData }], // Bind opacity to animated value
                }}>
                {props.children}
            </Animated.View>
        );
    };

    const RotationalView2 = props => {

        return (
            <Animated.View // Special animatable View
                style={{
                    ...props.style,
                    transform:  [{ rotate: RotateData2 }], // Bind opacity to animated value
                }}>
                {props.children}
            </Animated.View>
        );
    };

    function roll(top){
        setDiceNumber("...");
        if(setDisable !== undefined){
            setDisable(true);
        }
        setEnabled(false);
        if (top == 20){
            Animated.timing(rotationValue20, {
                toValue: 1,
                duration: 2000,
                useNativeDriver: true,
            }).start();
        }else{
            Animated.timing(rotationValue6, {
                toValue: 1,
                duration: 2000,
                useNativeDriver: true,
            }).start();
        }

        setTimeout(
            function() {
                setEnabled(true);
                setRotationValue20(new Animated.Value(0));
                setRotationValue6(new Animated.Value(0));
                setDiceNumber(Math.floor(Math.random() * top) + 1);
                if(setDisable !== undefined){
                    setDisable(false);
                }
            }.bind(this),
            2000
        );


    }



    const RotateData = rotationValue20.interpolate({
        inputRange: [0, 1],
        outputRange: ['0deg', '360deg'],
    });

    const RotateData2 = rotationValue6.interpolate({
        inputRange: [0, 1],
        outputRange: ['0deg', '360deg'],
    });

    return (
        <View style={diceRoller.diceRoller}>
            <Image source={handyDiceRoller} style={diceRoller.handyDiceImg} />
            <View style={diceRoller.diceButtons}>
                <BorderlessButton style={diceRoller.twentyDice} onPress={() => roll(6)} enabled={enabled}>
                    <RotationalView2><Image source={hideDice ? sixSidedMonster : sixSided} style={diceRoller.diceImage} /></RotationalView2>
                    <Text style={diceRoller.diceText}>ROLL A D6</Text>
                </BorderlessButton>
                <BorderlessButton style={hideDice ? styles.hide : diceRoller.twentyDice} onPress={() => roll(20)} enabled={enabled}>
                    <RotationalView><Image source={twentySided} style={diceRoller.diceImage}/></RotationalView>
                    <Text style={diceRoller.diceText}>ROLL A D20</Text>
                </BorderlessButton>
                <View style={diceRoller.rolledSection}>
                    <Text style={diceRoller.rolledSectionText}>TAP DIE</Text>
                    <Text style={healthTracker.healthText}>{diceNumber}</Text>
                    <Text style={diceRoller.rolledSectionText}>TO ROLL</Text>
                </View>
            </View>
        </View>
    );
}

const diceRollerStyles = StyleSheet.create({

});
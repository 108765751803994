import * as React from 'react';
import {Dimensions, PixelRatio, Platform} from "react-native";

const {
  width: SCREEN_WIDTH,
  height: SCREEN_HEIGHT,
} = Dimensions.get('window');
let screenWidth = 0;
// based on iphone 5s's scale
if (SCREEN_WIDTH > 500){
    screenWidth = 500;
}else{
    screenWidth = SCREEN_WIDTH;
}
const scale = screenWidth / 380;

export default function normalizer(size) {
    const newSize = size * scale
    if (Platform.OS === 'ios') {
      return Math.round(PixelRatio.roundToNearestPixel(newSize));
    } else {
      return Math.round(PixelRatio.roundToNearestPixel(newSize)) - 2;
    }
}

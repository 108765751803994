import React, { useState } from 'react';
import {StyleSheet, Text, View, Image} from 'react-native';
import {CheckBox} from "react-native-elements";
import { FontAwesome5 } from '@expo/vector-icons';
import normalizer from "./normalizer";

import starOff from "../assets/images/icons/ability_star.png";
import starOn from "../assets/images/icons/ability_star_on.png";

export default function AbilityPointTracker(props){

    let ultHolder = parseInt(props.ultNumber) ? parseInt(props.ultNumber) : 3;
    const cssShowAbility = ultHolder === 99 ? styles.hideSection : styles.checkboxButtons;
    const cssInfShowAbility = ultHolder === 99 ? styles.infAbilities : styles.hideSection;
    const starSizer = 210 / ultHolder;

    const checkboxButtonsImage = {
        height : starSizer < 100 ? starSizer : normalizer(100),
        width : starSizer < 100 ? starSizer : normalizer(100),
    };

    const [enabled0, setEnabled0] = useState(false);
    const [enabled1, setEnabled1] = useState(false);
    const [enabled2, setEnabled2] = useState(false);
    const [enabled3, setEnabled3] = useState(false);
    const [enabled4, setEnabled4] = useState(false);
    const [enabled5, setEnabled5] = useState(false);
    // const cssFullName = "styled-input-single buttons-" + ultHolder.length;

    function checkBoxKeeperFunction(checkBoxNumber){
        switch (checkBoxNumber) {
            case 0:
                setEnabled0(!enabled0);
                return;
            case 1:
                setEnabled1(!enabled1);
                return;
            case 2:
                setEnabled2(!enabled2);
                return;
            case 3:
                setEnabled3(!enabled3);
                return;
            case 4:
                setEnabled4(!enabled4);
                return;
            case 5:
                setEnabled5(!enabled5);
                return;
        }

    }

    function checkBoxKeeper(checkBoxNumber){
        switch (checkBoxNumber) {
            case 0:
                return enabled0;
            case 1:
                return enabled1;
            case 2:
                return enabled2;
            case 3:
                return enabled3;
            case 4:
                return enabled4;
            case 5:
                return enabled5;
        }

    }

    let createCheckboxes = () => {
        let checkboxRow = [];
        for (let i = 0; i < ultHolder; i++) {
            checkboxRow.push(<CheckBox
                key={i}
                checkedIcon={<Image source={starOn} style={checkboxButtonsImage} />}
                uncheckedIcon={<Image source={starOff} style={checkboxButtonsImage} />}
                checked={checkBoxKeeper(i)}
                onPress={() => checkBoxKeeperFunction(i)}
            />);
        }
        return checkboxRow;
    };

    return (
        <View style={styles.container}>
            <Text style={styles.headerText}>Heroic Ability Tracker</Text>
            <Text style={styles.subHeaderText}>Tap a star, fill a star, easy peasy!</Text>
            <View style={cssShowAbility}>
                {createCheckboxes()}
            </View>
            <Text style={cssInfShowAbility}>
                <FontAwesome5 name ='infinity' size={normalizer(75)} />
            </Text>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        marginTop: normalizer(20),
        marginBottom: normalizer(10),
        marginRight: normalizer(20),
        marginLeft: normalizer(20),
        borderWidth: 3,
        borderRadius: 20,
        borderColor: '#009862',

    },
    checkboxButtons: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        marginTop: 10,
    },
    headerText: {
        fontSize: normalizer(20),
        fontFamily: 'colby-bold',
        color: '#009862',
        textTransform: 'uppercase',
        padding: 0,
        textAlign: 'center',
    },
    subHeaderText: {
        fontSize: normalizer(15),
        fontFamily: 'colby',
        textAlign: 'center',
    },
    infAbilities: {
        textAlign: 'center',
        fontSize: 75,
    },
    hideSection: {
        display: 'none',
    },
});

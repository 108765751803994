import * as React from 'react';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { getFocusedRouteNameFromRoute } from '@react-navigation/native';
import HeroScreen from '../screens/HeroScreen';
import MonsterScreen from '../screens/MonsterScreen';
import {Image, StatusBar, StyleSheet, Text} from "react-native";
import normalizer from "../components/normalizer";

const BottomTab = createBottomTabNavigator();
const INITIAL_ROUTE_NAME = 'Heroes';
import {styles} from '../assets/styles/Styles';

import swordIcon from "../assets/images/icons/swords_icon.png";
import monsterIcon from "../assets/images/icons/monster_icon.png";
import {useEffect} from "react";


export default function BottomTabNavigator({ navigation, route }) {
    // Set the header title on the parent stack navigator depending on the
    // currently active tab. Learn more in the documentation:
    // https://reactnavigation.org/docs/en/screen-options-resolution.html

    useEffect(() => {
        navigation.setOptions({
            headerTitle: getHeaderTitle(route),
            headerTitleAlign: 'center',
            headerTitleStyle: styles.headerBar,
            headerStyle: { borderBottomWidth: 0, },
            headerLeft: () => (<Text></Text>),
        });
    });

    return (
        <BottomTab.Navigator
            screenOptions={({ route }) => ({
                tabBarIcon: ({ focused}) => {
                    if (route.name === 'Heroes') {
                        if(focused){
                            StatusBar.setBarStyle('light-content');
                            return <Image source={swordIcon} style={bottomTabStyles.activeIcon} />;
                        }else{
                            return <Image source={swordIcon} style={bottomTabStyles.inactiveIcon} />;
                        }
                    } else if (route.name === 'Monsters') {
                        if(focused){
                            StatusBar.setBarStyle('light-content');
                            return <Image source={monsterIcon} style={bottomTabStyles.activeIcon} />;
                        }else{
                            return <Image source={monsterIcon} style={bottomTabStyles.inactiveIcon} />;
                        }
                    }

                    // You can return any component that you like here
                },
            })}
            tabBarOptions={{
                showIcon: true,
                showLabel: true,
                lazyLoad: true,
                activeTintColor: '#ffffff',
                inactiveTintColor: '#777777',
                labelStyle: {
                    fontSize: normalizer(15),
                },
                tabStyle:{
                    padding: normalizer(10),
                    height: normalizer(60),
                },
                style: {
                    backgroundColor: 'rgba(0,0,0,.9)',
                    borderTopWidth: 0,
                    position: 'absolute',
                    left: 25,
                    right: 25,
                    bottom: 40,
                    borderTopRightRadius: 20,
                    borderTopLeftRadius: 20,
                    borderBottomRightRadius: 20,
                    borderBottomLeftRadius: 20,
                    height: normalizer(55),
                },
            }}
        >
            <BottomTab.Screen
                name="Heroes"
                component={HeroScreen}
                options={{
                    title: 'Heroes',
                    tabBarLabel: 'HEROES',

                }}
            />
            <BottomTab.Screen
                name="Monsters"
                component={MonsterScreen}
                options={{
                    title: 'Monsters',
                    tabBarLabel: 'CREATURES',
                }}
            />
        </BottomTab.Navigator>
    );
}

function getHeaderTitle(route) {
    const routeName = getFocusedRouteNameFromRoute(route) ?? INITIAL_ROUTE_NAME;

    switch (routeName) {
        case 'Heroes':
            return 'Choose Your Hero';
        case 'Monsters':
            return 'Choose Your Creature';
    }
}

const bottomTabStyles = StyleSheet.create({
    activeIcon:{
        flex: 1,
        width: 200,
        height: 200,
        resizeMode: 'contain',

    },
    inactiveIcon:{
        flex: 1,
        width: 100,
        height: 100,
        resizeMode: 'contain',
        tintColor: '#777777'
    },
});

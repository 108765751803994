import React, {useEffect, useState} from 'react';
import {StyleSheet, Text, View} from 'react-native';
import normalizer from "./normalizer";
import heroNameFile from "../assets/names/names.json";
import heroStoryFile from "../assets/names/stories.json";


export default function HeroNameGenerator(props){

    const [charNameFirst, setCharNameFirst] = useState("Default");
    const [charNameLast, setCharNameLast] = useState("Hero");
    const [charStory, setCharStory] = useState("Nothing to see here");

    useEffect(() => {
        if(charNameFirst === "Default"){
            changeHeroName();
            changeHeroStory();
        }
    });

    let changeHeroName = () => {
        let firstNames;
        let lastNames;

        switch (props.heroRace) {
            case "Half-Orc":
                firstNames = heroNameFile["half-orc"].Names.female;
                firstNames = firstNames.concat(heroNameFile["half-orc"].Names.male);
                lastNames = heroNameFile["half-orc"].Names.last;
                break;
            case "Half-Elf":
                firstNames = heroNameFile["half-elf"].Names.female;
                firstNames = firstNames.concat(heroNameFile["half-elf"].Names.male);
                lastNames = heroNameFile["half-elf"].Names.last;
                break;
            case "Elven":
                firstNames = heroNameFile["elven"].Names.female;
                firstNames = firstNames.concat(heroNameFile["elven"].Names.male);
                lastNames = heroNameFile["elven"].Names.last;
                break;
            case "Firbulg":
                firstNames = heroNameFile["firbulg"].Names.female;
                firstNames = firstNames.concat(heroNameFile["firbulg"].Names.male);
                lastNames = heroNameFile["firbulg"].Names.last;
                break;
            case "Half-Ogre":
                firstNames = heroNameFile["half-ogre"].Names.female;
                firstNames = firstNames.concat(heroNameFile["half-ogre"].Names.male);
                lastNames = heroNameFile["half-ogre"].Names.last;
                break;
            case "Gublin":
                firstNames = heroNameFile["gublin"].Names.female;
                firstNames = firstNames.concat(heroNameFile["gublin"].Names.male);
                lastNames = heroNameFile["gublin"].Names.last;
                break;
            case "Dragonborn":
                firstNames = heroNameFile["dragonborn"].Names.female;
                firstNames = firstNames.concat(heroNameFile["dragonborn"].Names.male);
                lastNames = heroNameFile["dragonborn"].Names.last;
                break;
            case "Dwarven":
                firstNames = heroNameFile["dwarven"].Names.female;
                firstNames = firstNames.concat(heroNameFile["dwarven"].Names.male);
                lastNames = heroNameFile["dwarven"].Names.last;
                break;
            case "Halfling":
                firstNames = heroNameFile["halfling"].Names.female;
                firstNames = firstNames.concat(heroNameFile["halfling"].Names.male);
                lastNames = heroNameFile["halfling"].Names.last;
                break;
            case "Croaker":
                firstNames = heroNameFile["croaker"].Names.female;
                firstNames = firstNames.concat(heroNameFile["croaker"].Names.male);
                lastNames = heroNameFile["croaker"].Names.last;
                break;
            case "Gnome":
                firstNames = heroNameFile["gnome"].Names.female;
                firstNames = firstNames.concat(heroNameFile["gnome"].Names.male);
                lastNames = heroNameFile["gnome"].Names.last;
                break;
            case "Human":
                firstNames = heroNameFile["human"].Names.female;
                firstNames = firstNames.concat(heroNameFile["human"].Names.male);
                lastNames = heroNameFile["human"].Names.last;
                break;
            case "Tiefling":
                firstNames = heroNameFile["tiefling"].Names.female;
                firstNames = firstNames.concat(heroNameFile["tiefling"].Names.male);
                lastNames = heroNameFile["tiefling"].Names.last;
                break;
            case "Minotaur":
                firstNames = heroNameFile["minotaur"].Names.female;
                firstNames = firstNames.concat(heroNameFile["minotaur"].Names.male);
                lastNames = heroNameFile["minotaur"].Names.last;
                break;
            default:
                firstNames = heroNameFile["tutorial"].Names.female;
                firstNames = firstNames.concat(heroNameFile["tutorial"].Names.male);
                lastNames = heroNameFile["tutorial"].Names.last;
                break;
        }


        let selectedFirstName =
            firstNames[Math.floor(Math.random() * (firstNames.length))];
        let selectedLastName =
            lastNames[Math.floor(Math.random() * (lastNames.length))];

        setCharNameFirst(selectedFirstName);
        setCharNameLast(selectedLastName);
    };

    let changeHeroStory = () => {
        let adjective = heroStoryFile.adjective;
        let location = heroStoryFile.location;
        let backstory = heroStoryFile.backstory;

        let selectedAdjective =
            adjective[Math.floor(Math.random() * (adjective.length))];
        let selectedLocation =
            location[Math.floor(Math.random() * (location.length))];
        let selectedBackstory =
            backstory[Math.floor(Math.random() * (backstory.length))];

        setCharStory(selectedAdjective.trim().replace(/^\w/, (c) => c.toUpperCase()) + " " + props.heroRace.toLowerCase() + " " + props.heroClass.toLowerCase() + " from " + selectedLocation + " who " + selectedBackstory);
    };

        return (
            <View style={styles.container}>
                <Text style={styles.headerText}>Your hero is</Text>
                <Text style={styles.heroFirstNameText}>{charNameFirst}</Text>
                <Text style={styles.heroLastNameText}>{charNameLast}</Text>
                <Text style={styles.heroStoryText}>{charStory}</Text>
            </View>
        );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    headerText: {
        fontSize: normalizer(15),
        fontFamily: 'colby-bold',
    },
    heroFirstNameText: {
        fontSize: normalizer(25),
        fontFamily: 'colby-bold',
        color: '#009862',
        textTransform: 'uppercase',
        padding: 0,
        marginBottom: normalizer(-5),
        marginTop: normalizer(-5),
    },
    heroLastNameText: {
        fontSize: normalizer(20),
        fontFamily: 'colby-bold',
        textTransform: 'uppercase',
    },
    heroStoryText: {
        fontSize: normalizer(14),
        fontFamily: 'colby',
    },
});
import * as React from 'react';
import {Image, ImageBackground, StyleSheet, Text, View, Platform} from 'react-native';
import {RectButton, ScrollView} from 'react-native-gesture-handler';

import barbarian from "../assets/images/heroes/CharSelect_Half-Orc_Barbarian.jpg";
import bard from "../assets/images/heroes/CharSelect_Half-Elf_Bard.jpg";
import cleric from "../assets/images/heroes/CharSelect_Elven_Cleric.jpg";
import druid from "../assets/images/heroes/CharSelect_Firbolg_Druid.jpg";
import fighter from "../assets/images/heroes/CharSelect_Half-Ogre_Fighter.jpg";
import mechanic from "../assets/images/heroes/CharSelect_Gublin_Mechanic.jpg";
import monk from "../assets/images/heroes/CharSelect_Dragonborn_Monk.jpg";
import paladin from "../assets/images/heroes/CharSelect_Dwarven_Paladin.jpg";
import pirate from "../assets/images/heroes/CharSelect_Croaker_Pirate.jpg";
import ranger from "../assets/images/heroes/CharSelect_Gnome_Ranger.jpg";
import rogue from "../assets/images/heroes/CharSelect_Halfling_Rogue.jpg";
import sorcerer from "../assets/images/heroes/CharSelect_Human_Sorcerer.jpg";
import warlock from "../assets/images/heroes/CharSelect_Tiefling_Warlock.jpg";
import wizard from "../assets/images/heroes/CharSelect_Minotaur_Wizard.jpg";
import tutorial from "../assets/images/heroes/CharSelect_Tutorial.jpg";
import {styles} from '../assets/styles/Styles';
import background from "../assets/images/Dungeon_Tile_001.jpg";


export default function HeroScreen({navigation}) {

  function Hero(heroImg, heroRace, heroClass, heroHealth, heroUlt) {
    this.heroImg = heroImg;
    this.heroRace = heroRace;
    this.heroClass = heroClass;
    this.heroHealth = heroHealth;
    this.heroUlt = heroUlt;
  }

  const HalfOrcBarbarian = new Hero(barbarian, 'Half-Orc', 'Barbarian', 12, 3);
  const HalfElfBard = new Hero(bard, 'Half-Elf', 'Bard', 10, 4);
  const ElvenCleric = new Hero(cleric, 'Elven', 'Cleric', 12, 3);
  const FirbulgDruid = new Hero(druid, 'Firbulg', 'Druid', 12, 4);
  const HalfOgreFighter = new Hero(fighter, 'Half-Ogre', 'Fighter', 14, 3);
  const GublinMechanic = new Hero(mechanic, 'Gublin', 'Mechanic', 11, 4);
  const DragonbornMonk = new Hero(monk, 'Dragonborn', 'Monk', 12, 3);
  const DwarvenPaladin = new Hero(paladin, 'Dwarven', 'Paladin', 12, 3);
  const CroakerPirate = new Hero(pirate, 'Croaker', 'Pirate', 8, 3);
  const HalflingRogue = new Hero(rogue, 'Halfling', 'Rogue', 8, 3);
  const GnomeRanger = new Hero(ranger, 'Gnome', 'Ranger', 10, 2);
  const HumanSorcerer = new Hero(sorcerer, 'Human', 'Sorcerer', 12, 3);
  const TieflingWarlock = new Hero(warlock, 'Tiefling', 'Warlock', 14, 99);
  const MinotaurWizard = new Hero(wizard, 'Minotaur', 'Wizard', 12, 3);
  const TutroailHero = new Hero(tutorial, 'Tutorial', 'Hero', 5, 3);

  const HeroList = [HalfOrcBarbarian, HalfElfBard, ElvenCleric, FirbulgDruid, HalfOgreFighter, GublinMechanic, DragonbornMonk, DwarvenPaladin, HalflingRogue, CroakerPirate, GnomeRanger, HumanSorcerer, TieflingWarlock, MinotaurWizard, TutroailHero];

  let createHeroBoxes = () => {
    let heroBoxes = [];
    HeroList.forEach((hero, index) =>
      heroBoxes.push(
          <HeroButton
              key= {index}
              image={hero.heroImg}
              race={hero.heroRace}
              gameclass={hero.heroClass}
              onPress={ () => navigation.push('Character', {charImage: hero.heroImg, charClass: hero.heroClass, charRace: hero.heroRace, charHealth: hero.heroHealth, ultimateUses: hero.heroUlt}) }
          />
      )
    );
    return heroBoxes;
  };

  return (
    <View style={styles.container}>
      <ImageBackground source={background} style={styles.background} imageStyle={styles.backgroundImage}>
        <ScrollView style={styles.scrollContainer}>
          <View style={styles.heroContainer}>
            {createHeroBoxes()}
          </View>
        </ScrollView>
      </ImageBackground>
    </View>
  );
}

function HeroButton({ image, race, gameclass, onPress }) {
  return (
      <RectButton style={styles.heroItem} onPress={onPress}>
           <Image source={image} style={styles.heroImage} />
            <Text style={styles.heroRaceText}>{race}</Text>
            <Text style={styles.heroClassText}>{gameclass}</Text>
      </RectButton>
  );
}

HeroScreen.navigationOptions = {
  headerLeft: () => (
        <Text>Home</Text>
  ),
};

const heroScreenStyles = StyleSheet.create({

});
